import styled from "styled-components";

export const HoverTransition = styled.div`

.hoverproperty:hover{
transform: translateY(-0.25rem);
transition:0.5s ease-in-out;
box-shadow: 0px 3px 15px black ;
cursor: pointer;
}


`