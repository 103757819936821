import React, { useState } from 'react'
import { GalleryBar, GalleryContact, GalleryContent, GalleryPoint, GalleryPointSec, GalleryPointSection, PicArea, StyledGallery } from './Style'
import { Col, Image, Modal, Row } from 'antd'
import Gallery_One from "../../../assets/images/Gallery_First.png"
import Gallery_Two from "../../../assets/images/Gallery_Second.png"
import Gallery_Three from "../../../assets/images/Gallery_Third.png"
import Gallery_Four from "../../../assets/images/Gallery_Fourth.png"
import { Heading, ModalBody } from '../../NavBar/stylenav'

const Gallery = () => {

    const [isOpen,setIsOpen] = useState(true);


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
  
    const showModal = () => {
      setOpen(true);
    };
  
    const handleOk = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
      }, 3000);
    };
  
    const handleCancel = () => {
      setOpen(false);
    };


    return (
        <StyledGallery id='Gallery'>
            <h1>Aari work <span>with</span> Trendy Designs<span>.</span></h1>
            <p>Aari World understands the customer needs and deliver the work with
                customer’s satisfaction at affordable price.
            </p>
          
            <Row>
                <Col span={24} md={12}>
                <PicArea>
                    <Row style={{rowGap:'10px'}}>
                        <Col span={24} md={12} >
                        <img src={Gallery_One} alt='pic' preview={false} style={{marginTop:'50px',}} />    
                        </Col>
                        <Col span={24} md={12} >
                        <img src={Gallery_Two} alt='pic' preview={false} style={{marginRight:'50px'}}/>
                        </Col>
                        <Col span={24} md={12} >
                        <img src={Gallery_Three} alt='pic' preview={false} style={{marginTop:'50px',}}   />
                        </Col>
                        <Col span={24} md={12} >
                        <img src={Gallery_Four} alt='pic' preview={false} style={{marginRight:'50px'}} />
                        </Col>
                    </Row>
                    </PicArea>
                        </Col>
                <Col span={24} md={12}>
                <GalleryContent>
                    <GalleryBar/>
                    <br/>
                    <h1> We deliver Desires & Joy<br/><span>Blouse Aari Design</span>. </h1>
                    <p>The Store was founded in 2020 as a one-stop solution to sourcing 
                        of everything to do with the art of embroidery and beading.
                        The Store was founded in 2020 as a one-stop solution to sourcing 
                        of everything to do with the art of embroidery and beading.</p>
                    <GalleryContact  onClick={showModal} style={{marginTop:'30px'}}><h4>Call Now</h4></GalleryContact><br/><br/>
                    <GalleryPointSection>
                <div style={{ display: 'flex', marginTop: '15px' }}><GalleryPoint />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fantastic designs </div>
                <div style={{ display: 'flex', marginTop: '15px' }}><GalleryPoint />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trendy designs at Affordable cost</div>
                <div style={{ display: 'flex', marginTop: '15px' }}><GalleryPoint />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New unique designs</div>
                <div style={{ display: 'flex', marginTop: '15px' }}><GalleryPoint />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;100 % Satisfaction</div>
                      </GalleryPointSection>
                </GalleryContent>
                </Col>
            </Row>
            <Modal
      bodyStyle={{padding:'0'}}
      style={{padding: '0px'}}
   
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
         
        ]}
      >
        <ModalBody>

        <Heading>
            <h4>Contact Details</h4>
            
          </Heading>

<h1>Contact Us via : </h1> <br/>
<h2>Phone 1 :+91 8300882101 <br/>
    Phone 2: +91 9688532695 <br/>
    Email : aariworld97@gmail.com
    </h2><br/><br/>
    <p style={{textAlign:'center'}}>"Your Satisfaction is Our Priority "</p>

                          

   </ModalBody>
   </Modal>
        </StyledGallery>
    )
}

export default Gallery