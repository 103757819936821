import HomePage from "./Modules/Home/Partials/HomePage";
import './App.css';
import GlobalStyle from './theme/GlobalStyle';
import AboutUs from "./Modules/About/Partials/AboutUs";
import OurServices from "./Modules/OurServices/Partials/OurServices";
import Gallery from "./Modules/Gallery/Partials/Gallery";
import Objectives from "./Modules/Objectives/Partials/Objectives";

import Footer from "./Modules/Footer/Partials/Footer";

import NavigationBar from "./Modules/NavBar/NavigationBar";
import { Route, Router } from "react-router-dom";
import { Switch } from "antd";
import { Demo } from "./Modules/Demo/Demo";



function App() {
  return (
    <div>
      <GlobalStyle/>
      <NavigationBar/>
      <HomePage/>
      <AboutUs/>
      <Gallery/>
      <Objectives/>
      <OurServices/>
      <Footer/>
    </div>
  );
}

export default App;
