import styled from "styled-components";

export const StyledObjectives = styled.div`
background: #FFFFFF;
padding-top: 100px;
padding-left: 60px;

@media (max-width:768px){
      padding-left: 0px;
};

& h2{
    font-size: 50px;
    padding-top: 15px;
    color:#555555 ;
    padding-left: 30px;

    @media (max-width:1215px){
    font-size: 40px;
    padding-left: 20px !important;
};

@media (max-width:768px){
    font-size: 30px;
    padding-left: 20px !important;
};
    
};

& span{
    font-size: 50px;
    color: #EE9591;

    @media (max-width:1215px){
    font-size: 40px;
};

@media (max-width:768px){
    font-size: 30px;
};
};

`

export const ObjectiveBar = styled.div`

height: 10px;
width: 100px;
background-color:#EE9591 ;
border-radius: 10px;
`

export const StyledCard1 = styled.div`

width: 100%;
height: 180px;
background: #FFFBFB;
box-shadow: 50px 50px 60px rgba(85, 85, 85, 0.2);
border-radius: 16px;
text-align: center;

& h1{
font-size: 16px;
margin-left:0 !important;
display: flex;
justify-content: center;
color: #888888;

};

& img{
width:100%;
align-items: center;
};

`

export const StyledCards = styled.div`

padding:10px;


`