import { Button } from "antd";
import styled from "styled-components";

export const StyledOurServices = styled.div`
padding-top:100px;
width:100%;
background:#FFFFFF;
text-align:center;
position: relative;
padding: 10px;
word-spacing: 5px;

& img{
position: absolute;
left: -25%;

@media (max-width:1245px) {
    display: none;
    
} 

};

& h1{
    margin-left:90px;
    font-size:40px;
    color:#555555;
    @media (max-width:768px){
        margin-left:20px;
   };
    

};

& span{
    color: #EE9591;
font-family: 'Roboto';
font-style: normal;
font-size: 40px;
@media (max-width:768px){

};
    
};

& h3{
    font-size: 16px;
    color: #888888;
    @media (max-width:768px){
    padding:10px;
};

}

& h4{
    margin-top:20px;
    font-size: 20px;
    color: #000000;
    padding: 70px;
    padding-top: 40px;
    @media (max-width:768px){
    padding: 0 ;
    font-size: 12px;
    margin-top:15px;
};
    
}
/* 
& img{
position: relative;
width: 100%;
height: 100%;
object-fit: cover;
top: 0;
left: -50%;

} */

`
export const About = styled.div`

text-align:center;
justify-content:center;
align-items:center;

@media (max-width: 768px) {
        margin-top:20px;
}


& h1{
    margin-left:90px;
    font-size:40px;
    color:#555555;
    justify-content:center;
    text-align:center;
    @media (max-width: 950px) {
        margin-left:20px;
}
};

& h2{
    color: #EE9591;

font-family: 'Roboto';
font-style: normal;
font-size: 40px;
};

& h3{
    font-size: 16px;
    color: #888888;
    align-items: center;

    @media (max-width:768px){
      padding:10px;
};

}

& h4{
    margin-top:20px;
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding-top: 40px;
    padding: 20px;

    @media (max-width:768px){
    /* padding: 0 ; */
    font-size: 12px;
    gap:20px;
    margin-top:15px;
};
}

`

export const StyledMatrix = styled.div`

& img{
    width: 100%;
    align-items: end;
    justify-content:end;
    justify-content:flex-end;
  }
`



export const AboutBar = styled.div`

height: 10px;
width: 100px;
background-color:#EE9591 ;
border-radius: 10px;
`

export const OurservicesContact = styled(Button)`



height:60px;
width:120px;
background-color: #EE9591;
border-radius:10px;
box-shadow: 10px 10px 30px rgba(234, 141, 136, 0.4);
text-align:center;

& p{
    color:white;

}
/* 
@media (max-width:773px){
        height: 40px;
        width: 100px ;
    } */
`