import React from 'react'
import { AddressContent, StyledAddress, StyledButton, StyledCircle, StyledFooter, StyledFooterAddress, StyledLogo, StyledMenu, StyledReview, StyledWordings } from './Style'
import { Col, Image, Row } from 'antd'
import Review from "../../../assets/images/Review.webp"
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import Footer_Pic from "../../../assets/images/Footer_Pic.png"
import Footer_Matrix from "../../../assets/images/Footer_Matrix.png"
import Footer_Address from "../../../assets/images/Footer_Address.png"

const Footer = () => {
  return (
    <StyledFooter>
            <StyledReview>
        <Row>   
            <Col span={10} offset={10}>
                <Row>
                    <Col span={24} md={24}>
                    <h1>Reviews</h1>
                <h2>What our <span>customer</span> say?</h2>
                    </Col>
                <Col span={24} md={4}>
                    <Image src={Review} alt='reviews' preview={false} />
                </Col>
                <Col span={24} md={20} sm={24}>
                    <h3>Sankar S </h3>
                    <p> Executive </p><br/>
                    <h4> I am very much satisfied with the design that Aari World delivers,
                        the staffs initially listens carefully our desires and then fullfills our desires and delivers on time.
                        </h4>
                        {/* <StyledButton><AiOutlineArrowLeft/></StyledButton>
                        <StyledButton><AiOutlineArrowRight/></StyledButton> */}
                </Col>
                </Row>
                </Col>
                 </Row>
                 </StyledReview>
             
                 <Row  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
             
                    <Col span={24} md={10}>
                  <StyledAddress>            
                    <Image src={Footer_Address} alt='pic' preview={false} />
                    <AddressContent>
                 
                    <h1>Aari World.</h1>
                    <p>An Aari shop which meets all your expectations <br/>and satisfies your desire.</p><br/>
                    <h2>Contact : 8300882101</h2><br/>  
                    <h3>Address :</h3><br/>
                    <p className='address'> No 25, J S Garden, <br/><br/>
                                            Sasthankarai <br/><br/>
                                            Colachel 629803
                                            </p>
                                            <Image src={Footer_Matrix} alt='matrix' prefix={false} />
                                            </AddressContent>                 
                   </StyledAddress>
                    </Col>
                    <Col span={24} md={4}>
                    <StyledMenu>
                    <h1>About</h1><br/>
                    <p>Home <br/><br/>
                        Gallery<br/><br/>
                        Blog<br/><br/>
                        Contact
                    </p>
                </StyledMenu>
                    </Col>
                    <Col span={24} md={10}>
                    <Image src={Footer_Pic} alt='image' preview={false} /> 
                    </Col> 
                 </Row>
              
    </StyledFooter>
  )
}

export default Footer