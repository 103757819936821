import { Button } from "antd";
import styled from "styled-components";

export const StyledGallery = styled.div`

background: #FFFFFF;
text-align: center;
padding-top: 100px;


& h1{
color:#555555 ;
font-size: 70px;


@media (max-width:1215px){
    font-size: 50px;
};

@media (max-width:768px){
    font-size: 40px;
    padding-left: 10px;
};
};

& span{
    color: #EE9591;
    font-size: 70px;

    @media (max-width:1215px){
    font-size: 50px;
};
@media (max-width:768px){
    font-size: 40px;
};


};

& img{
    border-radius: 20px;
}

& p{
    color:#888888;
    @media (max-width:768px){
    padding:25px;
    text-align: left;
    color:#888888;
};
};

`

export const PicArea = styled.div`

margin-top:25px;

& img{
    width:75%;

     @media (max-width:750px) {
        
        width:50%;
        margin-right:0px !important;
        margin-top:0px !important;
    }
}


`

export const GalleryBar = styled.div`

height: 10px;
width: 100px;
background-color:#EE9591 ;
border-radius: 10px;
`

export const GalleryContent = styled.div`

margin-top: 25px;
text-align: left;


& h1{
    font-size: 50px;
    padding-top: 15px;

    @media (max-width:1215px){
    font-size: 40px;
};

@media (max-width:768px){
    font-size: 30px;
    padding-left: 20px;
    margin-top: 0;
};
    
};

& span{
    font-size: 50px;

    @media (max-width:1215px){
    font-size: 40px;
};

@media (max-width:768px){
    font-size: 30px;
};
};

& p{
    padding-top: 20px;
    color:#888888;
    text-align: center;

    @media (max-width:773px){
       padding-left:40px;       
    }
}
`


export const GalleryContact = styled(Button)`

height:60px;
width:120px;
background-color: #EE9591;
border-radius:10px;
box-shadow: 10px 10px 30px rgba(234, 141, 136, 0.4);
text-align:center;
align-items: center;
margin-top: 10px;



@media (max-width:773px ) {
margin-left: 35px;
}

& h4{
    color:white;
}
`

export const GalleryPoint = styled.div`

height: 11px;
width: 11px;
border-radius:20px ;
background-color: #EE9591;
box-shadow:4px 4px 4px rgba(0,0,0,1) ;

`

export const GalleryPointSection = styled.div`

display:'flex';
margin-top:100px;
font-size:16px;
color: #888888;
width:100%;
position: relative;
padding-left: 5%;

@media (max-width:773px){
    padding-left: 50px;
    margin-top: 0;
           
    }

& img{
   position: absolute;
   left:550px;
}

`