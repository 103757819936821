
import styled from 'styled-components'

export const TopNavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 10px;
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 999999999999;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  justify-content: space-between;

  & button {
    background-color: #EE9591;
    /* color: var(--white-color); */
    font-weight: 600;
    border-radius: 10px;
    padding: 25px 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    float: left;
    margin-right:20px;
    &:hover {
        background: linear-gradient(117.78deg, #F9A8A5 34.55%, #E28A86 79.96%)!important;
    }
  }
  @media (max-width:769px){
    height: 62px;
  }
`;
export const LogoPlace = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  padding-left: 50px;
  width: 150px;
  cursor: pointer;
  & img {
    width: 20vh;
    height: 14vh;
    top: 0;
    position: absolute;
  }
  @media screen and (max-width:992px) {
    padding-left: 10px;
    & img {
    width: 15vh;
    height: 10vh;
  }
  }

  @media screen and (max-width:750px) {
    padding-left: 10px;
    & img {
    width: 10vh;
    height: 7vh;
  }
  }
`;

export const LogoWord = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  padding-left: 70px;
  width: 100%;
  cursor: pointer;
  & img {
    width: 20vh;
    height: 5vh;
    top: 5;
    position: absolute;
  }
  @media screen and (max-width:992px) {
    padding-left: 20px;
    & img {
    width: 12vh;
    height: 2vh;
  }
  }

  @media screen and (max-width:750px) {
    padding-left: 10px;
    & img {
    width: 12vh;
    height: 2vh;
  }
  }
`;
export const NavCollapse = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-right: 40px;
  gap: 20px;
 
  & a {
    color: #555555;
    text-decoration: none;
    font-weight: 600;
    padding: 10px 2px;
    border-radius: 50px;
    transition: 0.5s;
    &:hover {
      color: #EE9591;
    }
    @media (max-width: 992px){
        color: #000;
    }
   
  }
  @media screen and (min-width: 1207px) and (max-width: 2900px){
        & a { padding: 10px 16px;}
    }
  @media screen and (max-width:992px) {
    position: absolute;

    display: ${(props) => props.State};
    flex-direction: column;
    padding-right: 0;
    padding-bottom: 20px;
    gap: 0;
    width: 100%;
    top: 61px;
    right: 0;
    background-color: #fff;

    & a {
      display: block;
      text-align: center;
      border-radius: 0;
      line-height: 2rem;
    }
  }
`;
export const Iconplace = styled.div`
  padding-right: 50px;
  display: none;

  & svg {
    color: var(--white-color);
    font-size: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width:992px) {
    display: flex;
    padding-left: 50px;
    margin-left: 100px;
  }
`;

// export const Button = styled.button`
// & .ant-btn-primary {
//     background-color: red;
// }

//   & .ant-btn-default
//    {
//     background-color: red;
//    }
//    & a {
//     background-color: red;
//    }
// `;
export const ModalBody = styled.div`
      padding: 60px 40px 40px !important;
    background-color: rgba(255, 134, 116, 0.20);
    background-size: cover;
    background-position: center;
    color: #572c5f;
    background-attachment: fixed; 
  
  /* @media (max-width: 992px) {
    margin-top:200px;
    
  }   */
  
    & ::placeholder {
font-size: 17px;
font-weight: 600;
font-family: var(--font-family3);
color:#adaec1;

}


& .ant-btn {
  color: #fff;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 38px;
    width: 100%;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 5px;
    border: none;
    display: inline-block;
    transition: all 0.6s ease 0s;
}
`;


export const Heading = styled.div`
color: #572c5f;
text-align: center;
    font-size: 33px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: -5px 0 10px; 

`;

