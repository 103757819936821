import Satisfaction from "../../assets/images/Satisfaction.png"
import Understanding from "../../assets/images/Understanding.png"
import Best from "../../assets/images/Best.png"
import PerfectDelivery from "../../assets/images/PerfectDelivery.png"
import Affordable from "../../assets/images/Affordable.png"
import DigitalMenu from "../../assets/images/DigitalMenu.png"
import TrendyDesign from "../../assets/images/TrendyDesign.png"
import ProductKnowledge from "../../assets/images/ProductKnowledge.png"
import styled from "styled-components"



export const NavData = [
  {
    name: "Home",
    path: "#Home",
  },
  {
    name: "Gallery",
    path: "#Gallery",
  },
  {
    name:"Blog",
    path:"#Blog",
  },
  {
    name:"About",
    path:"#About",
  },

];

export const ObjectiveData = [
    {
      key:'1',
      Pic:Satisfaction,
      Title:'Satisfaction'
    },
    {
      key:'2',
      Pic:Understanding,
      Title:'Understanding'
    },
    {
      key:'3',
      Pic:Best,
      Title:'Best'
    },
    {
      key:'4',
      Pic:PerfectDelivery,
      Title:'Perfect Delivery'
    },
    {
      key:'5',
      Pic:Affordable,
      Title:'Affordable'
    },
    {
      key:'6',
      Pic:DigitalMenu,
      Title:'Digital Menu'
    },
    {
      key:'7',
      Pic:TrendyDesign,
      Title:'Trendy Design'
    },
    {
      key:'8',
      Pic:ProductKnowledge,
      Title:'Product Knowledge'
    }
  ]

