import styled from "styled-components";

export const StyledAbout = styled.div`

width: 100%;
background-color: #FFFFFF;
padding: 0px 40px 20px 60px;
display: flex;
align-items: center;
justify-content: center;


`

export const StyledCard = styled.div`

background:  linear-gradient(117.78deg, #F9A8A5 34.55%, #E28A86 79.96%);
box-shadow: -40px 40px 50px rgba(85, 85, 85, 0.14);
border-radius: 20px;
text-align: center;
border: 8px solid #F7F7F7;
color: white;
padding:10px;

& h1{

font-size: 90px;
text-align: left;

@media (max-width:1215px){
    font-size: 50px;
};

};

.bottom{
    padding-top: 20px;
font-size: small;
color: white;
}
   
.contentStyle {
    height: 160px;
    line-height: 160px;
    text-align: center;
    color: white;
    /* background: #364d79; */
}

& p{
    color: white;
}
`
export const AboutusContent = styled.div`


& h1{
color: #EE9591;
font-size:50px;

@media (max-width:1215px){
    font-size: 30px;
};


};

& p{
    color: #888888;
}

& span{
color: #555555;
font-size:50px;

@media (max-width:1215px){
    font-size: 30px;
};


};

& p{
    margin-top: 15px;
};

& h3{
font-size: 12px;
margin-top: 5px;
};

& h4{
    font-size: 14px;
    margin-top: 25px;
};

& h5{
    font-size: 16px;
    margin-top: 5px;
};



`


export const AboutBar = styled.div`

height: 10px;
width: 100px;
background-color:#EE9591 ;
border-radius: 10px;
`