import React,{useState} from 'react';
import {Iconplace, LogoPlace, NavCollapse, TopNavBar,Heading,ModalBody, LogoWord} from "./stylenav";
import {MdOutlineMenu} from 'react-icons/md'
import {AiOutlineClose} from 'react-icons/ai'
import { NavData } from '../../assets/data/data';
import logo from "../../assets/images/logo.png";
import { Button,Image,Modal} from 'antd';
import Aari_World_Wordings from "../../assets/images/Aari_World_Wordings.png"

const NavigationBar = () => {
  
    const [isOpen,setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
  
    const showModal = () => {
      setOpen(true);
    };
  
    const handleOk = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
      }, 3000);
    };
  
    const handleCancel = () => {
      setOpen(false);
    };

  

  return (
    <>
    <TopNavBar>
        <LogoPlace>
          <a href='#Home'>
          <img src={logo} alt="" />
          </a>
        </LogoPlace>
        <LogoWord>
        <a href='#Home'>
        <img src={Aari_World_Wordings} alt='Aari World' preview={false} />
        </a>
        </LogoWord>
        <NavCollapse State={`${isOpen ? "none" : "flex"}`}>
          {NavData.map(({name, path}, i) => {
          return (
             
            <a key={i}
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
              href={path}
            >
             {name}
            </a>
           )
          })}

<Button type='primary'onClick={showModal}>Contact</Button>
        </NavCollapse>

           
      <Modal
      bodyStyle={{padding:'0'}}
      style={{padding: '0px'}}
   
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
         
        ]}
      >
        <ModalBody>

        <Heading>
            <h4>Contact Details</h4>
            
          </Heading>

<h1>Contact Us via : </h1> <br/>
<h2>Phone 1 :+91 8300882101 <br/>
    Phone 2: +91 9688532695 <br/>
    Email : aariworld97@gmail.com
    </h2><br/><br/>
    <p style={{textAlign:'center'}}>"Your Satisfaction is Our Priority "</p>

                          

   </ModalBody>
      
      </Modal>
        
        <Iconplace
          onClick={() => {
            setIsOpen((prev) => !prev);
           
          }}
        >
          {isOpen ? <MdOutlineMenu /> : <AiOutlineClose />}
        </Iconplace>
      </TopNavBar>
    </>
  )
}

export default NavigationBar;