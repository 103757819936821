import React from 'react'
import { ObjectiveBar, StyledCard1, StyledCards, StyledObjectives } from './Style'
import { Col, Image, Row } from 'antd'
import { ObjectiveData } from '../../../assets/data/data'
import Objective_Pic from '../../../assets/images/Objective_Pic.png'


const Objectives = () => {
  return (
    <StyledObjectives  id='Blog'>
      {/* <img src={Objective_Pic} alt="obj" className='backimg' /> */}
      <ObjectiveBar/>
      <h2>We work your orders with our key <br/>objectives<span> @ Aari World</span>.</h2><br/><br/><br/><br/>
        <Row >
    
        {ObjectiveData.map(({ Pic, Title }, i) => {
          return (
            <Col span={24} xs={24} sm={12} md={6} lg={6} key={i} >
              <StyledCards >
                <StyledCard1 >
                <Image src={Pic} preview={false}/>
              <h1>{Title}</h1>
                </StyledCard1>
                </StyledCards>
            </Col>
          )
        })}
        
        </Row>
      
    </StyledObjectives>
  )
}

export default Objectives