import React from 'react'
import { AboutBar, AboutBorder, AboutusContent, FirstCard, SecondCard, StyledAbout, StyledCard } from './Style'
import { Carousel, Col, Row } from 'antd'

const AboutUs = () => {
    return (
        <StyledAbout>
            <Row gutter={[24,24]}>
                <Col span={24} md={10}>
                <AboutusContent>
                    <h1>Aari<span>&nbsp;World</span>.</h1>
                    {/* <h3></h3> */}
                    <p>We at Aari world undertake Aari work,
                        Hand work orders from Boutiques,<br/>
                        Tailor shops and Individual customers
                        at lowest rate in Market.
                      </p>
                  
                        <p>AARI, ZARDOSHI, MAGGAM WORK,
                        MIRROR WORK, CUT WORK, BEAD WORK,<br/>
                        MACHINE EMBROIDERY, SAREE TASSELS
                        AND ALL KIND OF ORDERS TAKEN
                        </p>
                        <br/><AboutBar/>
                        </AboutusContent>
                </Col>
                    <Col span={24} md={7}>
                    <StyledCard>
                        {/* <h1>50%</h1> */}
                        <Carousel autoplay dots={false}>
    <div>
      <h1 className='contentStyle'>50%</h1>
      <p>Bingun bagaimana caranya memulai bisnis healthy beauty.
        Sedangkan demand di sektor ini begitu 
            </p>
            <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1 className='contentStyle'>23%</h1>
      <p >Bingun bagaimana caranya memulai bisnis healthy beauty.
        Sedangkan demand di sektor ini begitu 
        </p>
        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1 className='contentStyle'>40%</h1>
      <p>Bingun bagaimana caranya memulai bisnis healthy beauty.
        Sedangkan demand di sektor ini begitu 
        </p>
        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1 className='contentStyle' >75%</h1>
      <p>Bingun bagaimana caranya memulai bisnis healthy beauty.
        Sedangkan demand di sektor ini begitu 
        </p>
        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
  </Carousel>

                    </StyledCard>
                </Col>
                <Col span={24} md={7}>
                    <StyledCard>
                        {/* <h1>23,7%</h1> */}
                        <Carousel autoplay  speed={1000} fade={1000} dots={false}>
    <div>
      <h1 className='contentStyle'>15%</h1>
      <p>
                            Khawatir butuh modal usaha yang sangat besar.
                            Padahal banyak cara agar dapat berbinis di
                            sektor ini 
                        </p>
                        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1 className='contentStyle'>23%</h1>
      <p>
                            Khawatir butuh modal usaha yang sangat besar.
                            Padahal banyak cara 
                            sektor ini 
                        </p>
                        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1  className='contentStyle'>87%</h1>
      <p>
                            Khawatir butuh modal usaha yang sangat besar.
                            Padahal banyak cara agar dapat berbinis di
                            sektor ini 
                        </p>
                        <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
    <div>
      <h1  className='contentStyle'>48%</h1>
          <p>
            Khawatir butuh modal usaha yang sangat besar.
            Padahal banyak cara agar 
            sektor ini 
          </p>
            <p className='bottom'>source involve cewekbanget id beautypreneur</p>
    </div>
 </Carousel>
 
                    </StyledCard>
                </Col>
            </Row>
        </StyledAbout>
    )
}

export default AboutUs