import React, { useState } from 'react'
import { About, OurservicesContact, ProfileCard1, ProfileCard2, ProfileCard3, ProfileCard4, StyledMatrix, StyledOurServices } from './Style'
import { Col, Image, Modal } from 'antd'
import Our_Services from "../../../assets/images/Our_Services.png"
import { Heading, ModalBody } from '../../NavBar/stylenav'

const OurServices = () => {

  const [isOpen,setIsOpen] = useState(true);


  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <StyledOurServices id='About'>
      <img src={Our_Services} alt='pic' preview={false} />
      <h1>
        Our <span>Services</span>.
      </h1>
      <h3>We at Aari world undertake Aari work, Hand work orders from Boutiques, Tailor shops and Individual customers at lowest rate in Market.</h3>

<h4>Aari Design Work | Zardoshi | Maggam (Maham) Work | Cut work | Mirror Work | Bead work | Net Attached work Chudi Design | Wedding frock | Gowns | Simple Frock | Extra | Extra|</h4>

      <About>
        <h1>About <span>Aari World</span>.</h1>
        <h3>We at Aari world undertake Aari work, Hand work orders from Boutiques, Tailor shops and Individual customers at lowest rate in Market.</h3>

<h4>The Store was founded in 2020 as a one-stop solution to sourcing of everything to do with the art of embroidery and beading.
Aari World is empowered by Highly Qualified embroidery designers with 00 years of experience. We are more concious about the current market status and standards. We the only firm provides your desired designs at affordable prices.</h4>
      </About>
<OurservicesContact onClick={showModal}><p>
Call Now</p>
</OurservicesContact>
<Modal
      bodyStyle={{padding:'0'}}
      style={{padding: '0px'}}
   
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
         
        ]}
      >
        <ModalBody>

        <Heading>
            <h4>Contact Details</h4>
            
          </Heading>

<h1>Contact Us via : </h1> <br/>
<h2>Phone 1 :+91 8300882101 <br/>
    Phone 2: +91 9688532695 <br/>
    Email : aariworld97@gmail.com
    </h2><br/><br/>
    <p style={{textAlign:'center'}}>"Your Satisfaction is Our Priority "</p>

                          

   </ModalBody>
   </Modal>
    </StyledOurServices>
  )
}

export default OurServices
