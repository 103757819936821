import styled from "styled-components";


export const StyledFooter = styled.div`

background-color: #FFFFFF;
padding-top: 50px;

& img{
    width: 100%;
}

`
export const StyledReview = styled.div`
width:100%;

@media (max-width:773px){
    .ant-col-offset-10 {
   margin-inline-start: 20%;
      }
    };

 
    ;

& h1{
    color:#EE9591;
};

& h2{
font-size: 50px;
color: #555555;

@media (max-width: 950px) {
    font-size: 30px;
    align-items: center;

}
};

& span{
    font-size: 50px;
    color:#EE9591;
   
@media (max-width: 950px) {
    font-size: 30px;
}
};

& img{
    border-radius: 0  200px 200px 200px  ;
    padding-top: 10px;
};

& h3{
    color:#888888;
    padding-left: 20px;
    font-size: 25px;

};

& p{
    color: #EE9591;
    font-size: 20px;
    padding-left: 20px;
    padding-top: 3px;
}

& h4{
    color:#888888;
    padding-left: 20px;
    font-size:20px;

       @media (max-width:773px){
    align-items:start;
    text-align: start;
    display: flex;

      }
}

@media (max-width: 950px) {
    font-size: 30px;
}

`
export const StyledAddress = styled.div`

width:100%;
border-radius: 0  200px 0px 0px  ;
background-color: #EE9591;

@media (max-width: 750px) {
   margin-top: 20px;
}
& img{
    bottom: 0px;
}

`

export const StyledCircle = styled.div`

background-color:#FFFFFF;
height: 150px;
width:150px;
border-radius:100px;
margin-left:50px !important;


& img{
    width: 100%;
};

@media (max-width:1024px) {
    height: 100px;
    width: 100px;
};

@media (max-width:754px) {
    height: 50px;
    width: 50px;
};

`

export const StyledButton = styled.button`

height: 30px;
width: 50px;
margin-top: 10px;
color: #EE9591;
margin-left: 20px;


@media (max-width: 750px) {
    height: 20px;
    width:40px;
}

`
export const StyledMenu = styled.div`

align-items: center;
text-align:center;
justify-content: center;
padding-top:50px;



& h1{
font-size:18px ;
color:#EE9591 ;
};

& p{
font-size: 16px;
color:  #888888;
};

`

export const StyledLogo = styled.img`
width: 100%;

`


export const StyledWordings = styled.img`
width: 100%;
padding:10px;

`
export const AddressContent = styled.div`

color:#FFFFFF;
padding:50px 0px 0px 100px;
margin-top: 0px !important;
position: relative;

& img{
align-items:flex-end;
text-align: end;
display: flex;
position: absolute;
bottom: 0;

}

@media (max-width:754px) {
font-size:12px;
}; 

`