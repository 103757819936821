import { Button } from "antd";
import styled from "styled-components";

export const StyledHome = styled.div`

width:100%;
background-color: #FFFFFF;
color: #555555;
padding-top: 25px;

& h1{
    font-size: 70px; 

    @media (max-width:773px){
        font-size: 40px;       
    }
};

.head{
    font-size: 14px;
    color:#888888;
    margin-top: 30px;

    @media (max-width:768px){  
         padding:10px;
         margin-top: 0 px !important;
};
  };

`

export const HomeDot = styled.div`

width:11px;
height:11px;
background-color: #EE9591;
border-radius:20px;
box-shadow:4px 4px 4px rgba(0,0,0,1) ;

& h1{
    font-size: 16px;
}

`
export const Flex = styled.div`
display: flex;

`


export const HomePicture = styled.div`

& img{
    width:100%;
};

`

export const HomeCall = styled(Button)`

height: 50px;
width: 200px;
background-color: #EE9591;
border-radius:10px;
box-shadow: 10px 10px 30px rgba(234, 141, 136, 0.4);
text-align:center;
margin-top: 30px;

& p{
    color: white;
}

@media (max-width:773px){
        margin-left: 20px;       
    }
`
export const HomeContent = styled.div`

margin-top: 100px;
padding-left:50px;
width: 100%;

`
export const DotContent = styled.div`

color: #888888;
@media (max-width:773px){
    padding-left:50px ;         
    }
`

export const HomeDot2 = styled.div`

width:11px;
height:11px;
background-color: #EE9591;
border-radius:20px;
box-shadow:4px 4px 4px rgba(0,0,0,1) ;

& h1{
    font-size: 16px;
}

`